import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  DatePicker,
  Tag,
  Spin,
  PageHeader,
  Tabs,
  notification,
  message,
  InputNumber,
  Row,
  Space,
  Slider,
  Select,
  Col,
  Typography,
  Input,
  Table,
  Popover,
  Switch,
} from "antd";
import { CalibrationFileServices } from "../../../services/dataService";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { render } from "@testing-library/react";
import { text } from "d3";
import UserStats from "./userStats";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Name",
    key: "exercise_name",
    dataIndex: "exercise_name",
    render: (text: any, record: any) => {
      return (
        <a href={`/calibration-files/${record.id}`}>
          {text || record.exercise_name || record.exercise}
        </a>
      );
    },
    width: "10%",
  },
  {
    title: "Image",
    dataIndex: "img_url",
    key: "img_url",

    align: "center",
    width: "10%",
    render: (text: any, record: any) => (
      <a href={record.img_url} target="_blank">
        <img src={record.img_url} style={{ width: "100px" }} />
      </a>
    ),
  },
  {
    title: "Import Successful",
    dataIndex: "import_status.successful",
    key: "import_successful",

    align: "center",
    width: "10%",
    sorter: {
      compare: (a: any, b: any) =>
        a.import_status.successful - b.import_status.successful,
      multiple: 3,
    },
    render: (text: any, record: any) => (
      <span>{record.import_status.successful}</span>
    ),
  },
  {
    title: "Import Failed",
    dataIndex: "import_status.failed",
    key: "import_failed",
    align: "center",
    width: "10%",
    sorter: {
      compare: (a: any, b: any) =>
        a.import_status.failed - b.import_status.failed,
      multiple: 3,
    },
    render: (text: any, record: any) => (
      <span>{record.import_status.failed}</span>
    ),
  },
  {
    title: "Total Labelled",
    dataIndex: "total_labelled",
    key: "total_labelled",

    align: "center",
    width: "10%",
    sorter: {
      compare: (a: any, b: any) => a.total_labelled - b.total_labelled,
      multiple: 3,
    },
    render: (text: any, record: any) => <span>{record.total_labelled}</span>,
  },
];

const ExerciseStats = ({ data }: any) => {
  const [state, setState] = useState({
    search: "",
    typeFilter: "",
  });

  const [showDebugColumns, setShowDebugColumns] = useState(false);
  const { Column, ColumnGroup } = Table;

  const redirect = (exercise: string, to: string, params: any) => {
    if (to === "calibration") {
      params.exercise = exercise;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/calibrations/?${searchParams}`;
    } else if (to === "verification") {
      params.exercise = exercise;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/verify/?${searchParams}`;
    } else if (to === "label") {
      params.exercise = exercise;
      let searchParams = new URLSearchParams(params).toString();
      window.location.href = `/v2/label/?${searchParams}`;
    }
  };

  const debugColums = [
    "import_status.successful",
    "import_status.failed",
    "archived",
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography.Title level={5}>Exercise Stats:</Typography.Title>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <div>
              Debug Columns:{" "}
              <Switch
                onChange={setShowDebugColumns}
                checked={showDebugColumns}
              />
            </div>
            <Input.Search
              style={{ width: "200px" }}
              onChange={(e) =>
                setState((prev) => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
        </div>
        <Table
          // columns={columns as any}
          loading={!data}
          dataSource={
            state.search || state.typeFilter
              ? data
                  .filter(
                    (data: any) =>
                      data.exercise_name.includes(state.search) ||
                      data.exercise.includes(state.search)
                  )
                  .filter((data: any) => {
                    if (state.typeFilter === "predefined") {
                      return data.img_url;
                    } else if (state.typeFilter === "custom") {
                      return !data.img_url;
                    }
                    return true;
                  })
              : data
          }
          pagination={false}
          scroll={{ y: 600 }}
        >
          <Column
            title="Image"
            dataIndex="image"
            width="96px"
            key="image"
            render={(text: any, record: any) => {
              return record.img_url ? (
                <Popover
                  content={
                    // Show the video and autoplay
                    <video width="480" height="320" controls autoPlay>
                      <source src={record.video_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  }
                >
                  <img src={record.img_url} style={{ width: "64px" }} />
                </Popover>
              ) : (
                <img
                  src="https://storage.googleapis.com/train-app-assets/Frame%2017742_Small.png"
                  style={{ width: "64px" }}
                />
              );
            }}
          />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(text: any, record: any) => {
              return (
                <Row>
                  <a
                    href={`/calibration-files/${record.id}`}
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {text || record.exercise_name || record.exercise}
                  </a>
                </Row>
              );
            }}
            sorter={(a: any, b: any) =>
              a.exercise_name.localeCompare(b.exercise_name)
            }
          />
          <Column
            title="Type"
            dataIndex="type"
            key="type"
            width="100px"
            filterDropdown={() => (
              <div style={{ padding: "10px" }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select a type"
                  allowClear
                  onChange={(value) => {
                    setState((prev) => ({ ...prev, typeFilter: value }));
                  }}
                  value={state.typeFilter}
                >
                  <Select.Option value="predefined">Predefined</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                </Select>
              </div>
            )}
            render={(text: any, record: any) => {
              return record.img_url ? (
                <Tag color="green">PREDEFINED</Tag>
              ) : (
                <Tag color="orange">CUSTOM</Tag>
              );
            }}
          />

          {showDebugColumns && (
            <ColumnGroup title="Import Status">
              <Column
                align="center"
                title="Successful"
                dataIndex="import_status.successful"
                key="import_successful"
                render={(text: any, record: any) => (
                  <Button
                    type="link"
                    onClick={() =>
                      redirect(record.exercise, "calibration", {
                        import_status: "successful",
                      })
                    }
                  >
                    {record.import_status.successful || 0}
                  </Button>
                )}
                sorter={(a: any, b: any) =>
                  (a.import_status.successful || 0) -
                  (b.import_status.successful || 0)
                }
              />
              <Column
                align="center"
                title="Failed"
                dataIndex="import_status.failed"
                key="import_failed"
                render={(text: any, record: any) => (
                  // <span>{record.import_status.failed}</span>
                  <Button
                    type="link"
                    onClick={() =>
                      redirect(record.exercise, "calibration", {
                        import_status: "failed",
                      })
                    }
                  >
                    {record.import_status.failed || 0}
                  </Button>
                )}
                sorter={(a: any, b: any) =>
                  (a.import_status.failed || 0) - (b.import_status.failed || 0)
                }
              />
            </ColumnGroup>
          )}
          <ColumnGroup title="Label Status">
            <Column
              align="center"
              title="Labelled"
              dataIndex="total_labelled"
              key="total_labelled"
              render={(text: any, record: any) => (
                // <span>{record.labelling_status.labelled}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.exercise, "label", {
                      labelling_status: "labelled",
                    })
                  }
                >
                  {record.labelling_status.labelled || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.labelling_status.labelled || 0) -
                (b.labelling_status.labelled || 0)
              }
            />
            <Column
              align="center"
              title="Unlabelled"
              dataIndex="total_unlabelled"
              key="total_unlabelled"
              render={(text: any, record: any) => (
                // <span>{record.labelling_status.unlabelled}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.exercise, "label", {
                      labelling_status: "unlabelled",
                    })
                  }
                >
                  {record.labelling_status.unlabelled || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.labelling_status.unlabelled || 0) -
                (b.labelling_status.unlabelled || 0)
              }
            />
            <Column
              align="center"
              title="Unsure"
              dataIndex="unsure"
              key="unsure"
              render={(text: any, record: any) => (
                // <span>{record.labelling_status.unsure}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.exercise, "label", {
                      labelling_status: "unsure",
                    })
                  }
                >
                  {record.labelling_status.unsure || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.labelling_status.unsure || 0) -
                (b.labelling_status.unsure || 0)
              }
            />
          </ColumnGroup>

          <ColumnGroup title="Exercise Verification Status">
            <Column
              align="center"
              title="Verified"
              dataIndex="verified"
              key="verified"
              render={(text: any, record: any) => (
                // <span>{record.ex_verification_status.verified}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.exercise, "verification", {
                      ex_verification_status: "verified",
                    })
                  }
                >
                  {record.ex_verification_status.verified || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.ex_verification_status.verified || 0) -
                (b.ex_verification_status.verified || 0)
              }
            />
            
            <Column
              align="center"
              title="Unverified"
              dataIndex="unverified"
              key="unverified"
              render={(text: any, record: any) => (
                // <span>{record.ex_verification_status.unverified}</span>
                <Button
                  type="link"
                  onClick={() =>
                    redirect(record.exercise, "verification", {
                      ex_verification_status: "unverified",
                    })
                  }
                >
                  {record.ex_verification_status.unverified || 0}
                </Button>
              )}
              sorter={(a: any, b: any) =>
                (a.ex_verification_status.unverified || 0) -
                (b.ex_verification_status.unverified || 0)
              }
            />
          </ColumnGroup>
          <Column
            title="Archived Files"
            dataIndex="archived"
            key="archived"
            align="center"
            render={(text: any, record: any) => (
              <Button
                type="link"
                onClick={() =>
                  redirect(record.exercise, "calibration", {
                    is_archived: true,
                    import_status: "successful",
                  })
                }
              >
                {record.archived || 0}
              </Button>
            )}
            sorter={(a: any, b: any) => (a.archived || 0) - (b.archived || 0)}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            render={(text: any, record: any) => {
              const nonRejected =
                (record.ex_verification_status.verified || 0) +
                (record.ex_verification_status.unverified || 0) -
                (record.archived || 0);

              let com: any = null;

              if (record.labelling_status.labelled > 500) {
                com = <CheckSquareOutlined
                    style={{
                      color: "##1cc306",
                      fontSize: "20px",

                    }}
                  />
                
              }
              else if (record.ex_verification_status.verified > 500) {
                com = <CheckCircleOutlined
                    style={{
                      color: "#06a887",
                      fontSize: "20px",

                    }}
                  />
                
              }
              else if (nonRejected > 500) {
                com = <CheckCircleOutlined
                    style={{
                      color: "#ff9401",
                      fontSize: "20px",
                    }}
                  />
                
              }
              
              

              return com;
            }}
            sorter={(a: any, b: any) => (a.archived || 0) - (b.archived || 0)}
          />
        </Table>
      </div>
    </React.Fragment>
  );
};

const DashboardV2 = () => {
  const queryClient = useQueryClient();
  const [state, updateState] = useState({
    filters: { dateRange: [moment().subtract(2, "y"), moment()] },
  });

  const dashboardStats = useQuery(
    ["dashboardStats", state.filters],
    async () => {
      const response = await CalibrationFileServices.getDashboardData(
        {...state.filters, 
          start_date: state.filters.dateRange[0].format("YYYY-MM-DD"), 
          end_date: state.filters.dateRange[1].format("YYYY-MM-DD")}
      );
      return response.data;
    }
  );

  const onDateRangeChange = ([startDate, endDate]: any) => {
    updateState((prev) => ({
      ...prev,
      filters: { ...prev.filters, dateRange: [startDate, endDate] },
    }));
  };

  console.log(dashboardStats.data);

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        // onBack={() => window.history.back()}
        title="Dashboard"
        extra={[
          <RangePicker
            allowClear={true}
            value={state.filters.dateRange as any}
            onChange={onDateRangeChange}
            format="YYYY-MM-DD"
          />,
        ]}
      ></PageHeader>
      <ExerciseStats data={dashboardStats.data?.exercise_stats} />
      <UserStats data={dashboardStats.data?.user_stats} />
    </React.Fragment>
  );
};

export default DashboardV2;
